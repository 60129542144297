import {FC, ReactNode, useState} from 'react'
import {Box, Button, Card, CardHeader, FormControlLabel, Grid2 as Grid, Switch, Typography} from '@mui/material'
import {GenevaUserInterface} from '@/interfaces/user_types'
import ServerError from '@/views/error/ServerError'
import useGenevaFetch from '@/hooks/useGenevaFetch'
import DomainAddIcon from '@mui/icons-material/DomainAdd'
import GenevaNextLink from '@/components/widgets/Layout/GenevaNextLink'
import {TaskInterface} from '@/interfaces/task_types'
import GenevaIconAvatar from '@/components/widgets/GenevaIconAvatar'
import {getKeyForTask, getSimpleTaskDescription, getUrlForTask} from '@/utils/task_utils'
import {formatDate} from '@/utils/formatter'
import {getPrioritySmallIcon} from '@/components/task/TaskPrioritySmallIcon'
import {HtmlTooltip} from '@/components/widgets/HTMLTooltip'
import {DataGridPro, GridColDef} from '@mui/x-data-grid-pro'
import dynamic from 'next/dynamic'

const UserTasksModal = dynamic(() => import('@/components/user/UserTasksModal'), {ssr: false})

interface UserTasksCreatedByMeCardProps {
    application_user: GenevaUserInterface
}

interface LinkWrapperProps {
    line: TaskInterface
    children?: ReactNode
}

const LinkWrapper: FC<LinkWrapperProps> = (props) => {
    const {line, children} = props

    return (
        <GenevaNextLink key={getKeyForTask(line, 'USER CREATED BY')} href={getUrlForTask(line)} passHref>
            <Box sx={{cursor: 'pointer'}}>{children}</Box>
        </GenevaNextLink>
    )
}

const UserTasksCreatedByMeCard: FC<UserTasksCreatedByMeCardProps> = ({application_user}) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [isShowCompleted, setIsShowCompleted] = useState<boolean>(false)

    const getUrl = () => {
        let url = `/api/users/${application_user.id}/tasks_created_by_me`
        if (isShowCompleted) {
            url = url + '?include_completed=true'
        }
        return url
    }

    const {data, isLoading, error} = useGenevaFetch(getUrl())

    if (error) {
        console.error(error)
        return <ServerError />
    }

    const tasks = !isLoading && data ? (data.tasks as TaskInterface[]) : []

    const filteredTasks = tasks.filter((task) => task.assigned_to_application_user_id != application_user.id)
    const shownTasks = filteredTasks.slice(0, 10)
    const extraTasks = filteredTasks.length > 10 ? filteredTasks.slice(10) : []

    const columns: GridColDef[] = [
        {
            field: 'task_object_description',
            headerName: 'Client',
            flex: 0.5,
            align: 'left',
            renderCell: (cellValues) => {
                return <LinkWrapper line={cellValues.row}>{getSimpleTaskDescription(cellValues.row)}</LinkWrapper>
            },
        },
        {
            field: 'subject',
            headerName: 'Task Subject',
            flex: 1,
            align: 'left',
            renderCell: (cellValues) => {
                return (
                    <LinkWrapper line={cellValues.row}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            {getPrioritySmallIcon(cellValues.row.lu_task_priority_id, 2)}
                            {cellValues.row.description ? (
                                <HtmlTooltip title={cellValues.row.description}>
                                    <Box>{cellValues.value}</Box>
                                </HtmlTooltip>
                            ) : (
                                <Box>{cellValues.value}</Box>
                            )}
                        </Box>
                    </LinkWrapper>
                )
            },
        },
        {
            field: 'created_on',
            headerName: 'Created',
            flex: 0.5,
            align: 'left',
            renderCell: (cellValues) => {
                return <LinkWrapper line={cellValues.row}>{formatDate(cellValues.value)}</LinkWrapper>
            },
        },
        {
            field: 'assigned_to_application_user_description',
            headerName: 'Assigned To',
            flex: 0.5,
            align: 'left',
            renderCell: (cellValues) => {
                return <LinkWrapper line={cellValues.row}>{cellValues.value}</LinkWrapper>
            },
        },
        {
            field: 'due_date',
            headerName: 'Due',
            flex: 0.5,
            align: 'left',
            renderCell: (cellValues) => {
                return <LinkWrapper line={cellValues.row}>{formatDate(cellValues.value)}</LinkWrapper>
            },
        },
    ]

    return shownTasks.length > 0 || (isShowCompleted && isLoading) ? (
        <Grid size={6}>
            <Card>
                <CardHeader
                    avatar={
                        <GenevaIconAvatar>
                            <DomainAddIcon />
                        </GenevaIconAvatar>
                    }
                    title={
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography variant="h6">Tasks Created by Me</Typography>
                            <Box sx={{flexGrow: 1}} />
                            {extraTasks.length > 0 && (
                                <Button sx={{mr: 2}} variant="contained" onClick={() => setIsModalOpen(true)}>
                                    {`+ ${extraTasks.length} more`}
                                </Button>
                            )}
                            <FormControlLabel
                                sx={{mr: 2}}
                                control={
                                    <Switch
                                        color="primary"
                                        checked={isShowCompleted}
                                        edge="start"
                                        name="direction"
                                        onChange={(event) => {
                                            setIsShowCompleted(event.target.checked)
                                        }}
                                        sx={{ml: 1}}
                                    />
                                }
                                label="Show Completed"
                                labelPlacement="start"
                            />
                        </Box>
                    }
                />
                <DataGridPro
                    sx={{
                        border: '0px',
                        '& .MuiDataGrid-footerContainer': {
                            minHeight: 16,
                        },
                    }}
                    rowHeight={44}
                    getRowId={(row) => getKeyForTask(row, 'USER CREATED BY')}
                    columns={columns}
                    rows={shownTasks}
                    loading={isLoading}
                    hideFooterRowCount={true}
                />
            </Card>
            {isModalOpen && (
                <UserTasksModal tasks={extraTasks} onClose={() => setIsModalOpen(false)} open={isModalOpen} />
            )}
        </Grid>
    ) : null
}

export default UserTasksCreatedByMeCard
