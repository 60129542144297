import {FC, ReactNode, useState} from 'react'
import {Alert, Box, Card, CardHeader, Grid2 as Grid, IconButton, Typography} from '@mui/material'
import {GenevaUserInterface} from '@/interfaces/user_types'
import ServerError from '@/views/error/ServerError'
import {SupplierLeadInterface, SupplierPipelineControllerInterface} from '@/interfaces/supplier_types'
import useGenevaFetch from '@/hooks/useGenevaFetch'
import DomainAddIcon from '@mui/icons-material/DomainAdd'
import GenevaNextLink from '@/components/widgets/Layout/GenevaNextLink'
import AddIcon from '@mui/icons-material/Add'
import {SupplierLeadTaskInterface} from '@/interfaces/task_types'
import {useRouter} from 'next/router'
import {useSnackbar} from 'notistack'
import GenevaIconAvatar from '@/components/widgets/GenevaIconAvatar'
import {DataGridPro, GridColDef, GridToolbar} from '@mui/x-data-grid-pro'
import dynamic from 'next/dynamic'

const SupplierLeadTaskCreateModal = dynamic(() => import('@/components/task/SupplierLeadTaskCreateModal'), {ssr: false})

interface UserSupplierLeadsCardProps {
    application_user: GenevaUserInterface
}

interface LinkWrapperProps {
    line: SupplierPipelineControllerInterface
    children?: ReactNode
}

const LinkWrapper: FC<LinkWrapperProps> = (props) => {
    const {line, children} = props

    const link = `/supplier_lead/${line.supplier_lead?.id}`

    return (
        <GenevaNextLink key={link} href={link} passHref>
            <Box sx={{cursor: 'pointer'}}>{children}</Box>
        </GenevaNextLink>
    )
}

const UserSupplierLeadsCard: FC<UserSupplierLeadsCardProps> = ({application_user}) => {
    const router = useRouter()
    const {enqueueSnackbar} = useSnackbar()
    const {data, isLoading, error} = useGenevaFetch(`/api/users/${application_user.id}/open_supplier_leads`)
    const [isNewTaskModalOpen, setNewTaskModalOpen] = useState<boolean>(false)
    const [selectedSupplierLead, setSelectedSupplierLead] = useState<SupplierLeadInterface | null>(null)

    if (error) {
        console.error(error)
        return <ServerError />
    }

    const onClick = (controller: SupplierPipelineControllerInterface): void => {
        setSelectedSupplierLead(controller.supplier_lead as SupplierLeadInterface)
        setNewTaskModalOpen(true)
    }

    const onComplete = (newSupplierLeadTask: SupplierLeadTaskInterface): void => {
        onClose()
        enqueueSnackbar(`Task ${newSupplierLeadTask.subject} saved`, {
            variant: 'success',
        })
        router.push(`/supplier_lead/${newSupplierLeadTask.supplier_id}/tasks/${newSupplierLeadTask.id}`)
    }

    const onClose = (): void => {
        setSelectedSupplierLead(null)
        setNewTaskModalOpen(false)
    }

    const controllers =
        !isLoading && data ? (data.supplier_lead_pipeline_controllers as SupplierPipelineControllerInterface[]) : []

    const getCreatedDays = (ageInDays: number) => {
        if (ageInDays > 1) {
            return `${ageInDays} days ago`
        } else {
            return `${ageInDays} day ago`
        }
    }

    const getNextTaskDueCellInner = (controller: SupplierPipelineControllerInterface) => {
        const content = `${controller.formatted_next_due_task_due_date_and_time} (${controller.next_due_task_due_in_days_from_today} days)`
        if (new Date(controller.next_due_task_due_date_and_time) > new Date()) {
            return content
        } else {
            return (
                <Alert severity="error" sx={{py: 0}}>
                    {content}
                </Alert>
            )
        }
    }

    const getNextTaskDueCell = (controller: SupplierPipelineControllerInterface) => {
        if (controller.next_due_task_id && controller.supplier_lead) {
            return (
                <GenevaNextLink
                    href={`/supplier_lead/${controller.supplier_lead.id}/tasks/${controller.next_due_task_id}`}
                    key={`SUP_LEAD_TASK_${controller.next_due_task_id}`}
                >
                    <Box sx={{p: 0, cursor: 'pointer'}}>{getNextTaskDueCellInner(controller)}</Box>
                </GenevaNextLink>
            )
        } else {
            return (
                <Box sx={{display: 'flex'}}>
                    <Alert severity="error" sx={{py: 0}}>
                        <Typography>No next task</Typography>
                    </Alert>
                    <IconButton color="primary" size={'small'} sx={{m: 1}} onClick={() => onClick(controller)}>
                        <AddIcon fontSize="small" />
                    </IconButton>
                </Box>
            )
        }
    }

    let columns: GridColDef[] = [
        {
            field: 'supplier_description',
            headerName: 'Supplier Lead',
            flex: 0.5,
            renderCell: (cellValues) => {
                return <LinkWrapper line={cellValues.row}>{cellValues.value}</LinkWrapper>
            },
        },
        {
            field: 'age_in_days',
            headerName: 'Created',
            flex: 0.5,
            renderCell: (cellValues) => {
                return <LinkWrapper line={cellValues.row}>{getCreatedDays(cellValues.value)}</LinkWrapper>
            },
        },
        {
            field: 'next_due_task_id',
            headerName: 'Next Task Due',
            flex: 0.5,
            renderCell: (cellValues) => {
                return getNextTaskDueCell(cellValues.row)
            },
        },
    ]

    return controllers.length > 0 ? (
        <Grid size={6}>
            <Card>
                <CardHeader
                    avatar={
                        <GenevaIconAvatar>
                            <DomainAddIcon />
                        </GenevaIconAvatar>
                    }
                    title={'Open Suppliers Leads'}
                />
                <DataGridPro
                    sx={{border: '0px'}}
                    columns={columns}
                    rows={controllers}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                />
                {isNewTaskModalOpen && selectedSupplierLead && (
                    <SupplierLeadTaskCreateModal
                        supplier_lead={selectedSupplierLead}
                        onSave={onComplete}
                        onClose={onClose}
                        open={isNewTaskModalOpen}
                    />
                )}
            </Card>
        </Grid>
    ) : null
}

export default UserSupplierLeadsCard
